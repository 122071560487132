// Premium.tsx
import React, { useEffect, useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import { Button, Container, Typography, Box } from '@mui/material';
import Footer from './Footer';

const Premium: React.FC = () => {
  const [userStatus, setUserStatus] = useState<
    'loading' | 'notLoggedIn' | 'nonSubscriber' | 'subscriber'
  >('loading');
  const [error, setError] = useState('');

  useEffect(() => {
    checkUserSubscriptionStatus();
  }, []);

  const checkUserSubscriptionStatus = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true, // Attempt to bypass the local cache
      });

      // Directly fetch user attributes to ensure we are getting the most recent data
      const attributes = await Auth.userAttributes(user);
      const subscriptionStatusAttribute = attributes.find(
        (attr) => attr.Name === 'custom:subscriptionStatus',
      );
      const subscriptionStatus = subscriptionStatusAttribute
        ? subscriptionStatusAttribute.Value
        : null;

      if (subscriptionStatus === 'subscribed') {
        setUserStatus('subscriber');
      } else if (subscriptionStatus === 'nonSubscriber') {
        setUserStatus('nonSubscriber');
      } else {
        setUserStatus('nonSubscriber'); // Default to nonSubscriber if status is undefined or not found
      }
    } catch (error) {
      console.error('Error checking subscription status:', error);
      setError(
        'Failed to retrieve user subscription status. Please ensure you are logged in.',
      );
      setUserStatus('notLoggedIn');
    }
  };

  if (userStatus === 'loading') {
    return <div>Loading...</div>;
  } else if (userStatus === 'notLoggedIn') {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          background: 'white',
          fontFamily: 'Lato, sans-serif',
          alignItems: 'center',
        }}
      >
        {/* Content Box */}
        <Box
          sx={{
            flex: '1 0 auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontFamily: 'inherit', // Ensure inheritance of Lato font
          }}
        >
          <Container>
            <Typography variant="h5" gutterBottom>
              Access Denied
            </Typography>
            <Typography variant="body1">
              You must be logged in to view this content.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => (window.location.href = '/login')}
            >
              Log In
            </Button>
          </Container>
        </Box>
        <Footer />
      </Box>
    );
  } else if (userStatus === 'nonSubscriber') {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          background: 'white',
          fontFamily: 'Lato, sans-serif',
          alignItems: 'center',
        }}
      >
        {/* Content Box */}
        <Box
          sx={{
            flex: '1 0 auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontFamily: 'inherit', // Ensure inheritance of Lato font
          }}
        >
          <Container>
            <Typography variant="h5" gutterBottom>
              Subscribe to Premium
            </Typography>
            <Typography variant="body1">
              Get access to our monthly sneaker report for only $2.99 a month
            </Typography>
            {error && <Typography color="error">{error}</Typography>}
            <Button
              variant="contained"
              color="primary"
              onClick={() => (window.location.href = '/subscribe')}
            >
              Subscribe Now
            </Button>
          </Container>
        </Box>
        <Footer />
      </Box>
    );
  } else {
    // Premium content for subscribers
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          background: 'white',
          fontFamily: 'Lato, sans-serif',
          alignItems: 'center',
        }}
      >
        {/* Content Box */}
        <Box
          sx={{
            flex: '1 0 auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontFamily: 'inherit', // Ensure inheritance of Lato font
          }}
        >
          <div>
            {/* Placeholder for premium content */}
            <Typography variant="h4" gutterBottom>
              Welcome to the Premium Content!
            </Typography>
          </div>
        </Box>
        <Footer />
      </Box>
    );
  }
};

export default Premium;
