import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Button,
  Container,
  Typography,
  Box,
  ThemeProvider,
} from '@mui/material';
import { Hub } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import Footer from './Footer';
import theme from './theme';

const baseStripeCheckoutUrl = 'https://buy.stripe.com/6oE019bOc9gN5PO6oq';

const Subscribe: React.FC = () => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    checkUser();
    Hub.listen('auth', ({ payload: { event } }) => {
      if (event === 'signIn' || event === 'signOut') {
        checkUser();
      }
    });
  }, []);

  const checkUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const isPremiumSubscriber =
        user.attributes['custom:subscriptionStatus'] === 'subscribed';
      setSubscriptionStatus(isPremiumSubscriber ? 'premium' : 'free');
      setIsUserLoggedIn(true);
      setUserEmail(user.attributes.email); // Fetch and set the user's email address
    } catch {
      setIsUserLoggedIn(false);
    }
  };

  const handleSubscribeClick = () => {
    if (isUserLoggedIn) {
      // Encode the email to be URL-safe
      const encodedEmail = encodeURIComponent(userEmail);
      const customizedStripeCheckoutUrl = `${baseStripeCheckoutUrl}?prefilled_email=${encodedEmail}`;
      window.open(customizedStripeCheckoutUrl, '_blank', 'noopener,noreferrer');
    } else {
      navigate('/subscribe-login'); // Redirect to "/subscribe-login" if not signed in
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          background: 'white',
          alignItems: 'center',
        }}
      >
        {/* Content Box */}
        <Box
          sx={{
            flex: '1 0 auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 2, // Optional: add padding for better spacing
            mt: 3, // Add margin-top to move the content up within the Box
          }}
        >
          <Container
            maxWidth="sm"
            sx={{
              textAlign: 'center',
              marginTop: '1rem', // Move the content further up the page
              border: '2px solid #ccc', // Slightly larger border
              padding: '30px', // Slightly larger padding
              borderRadius: '10px',
            }}
          >
            <Typography variant="h3" gutterBottom>
              Premium Subscription
            </Typography>
            <Box my={4}>
              <Typography variant="h6" color="textSecondary">
                Get access to premium features for only $0.99/month.
              </Typography>
            </Box>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleSubscribeClick}
              sx={{
                padding: '10px 20px',
                fontSize: '1.2rem',
                backgroundColor: '#0C9FD4',
              }}
            >
              Subscribe Now
            </Button>
            <Box mt={5}></Box>
          </Container>
        </Box>
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default Subscribe;
