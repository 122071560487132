import React, { useState, useEffect } from 'react';
import { Auth } from '@aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Container,
  Box,
  Button,
  Paper,
  Grid,
  Avatar,
  Tooltip,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Footer from './Footer';

const stripeCheckoutUrl = 'https://buy.stripe.com/6oE019bOc9gN5PO6oq';
const stripeCustomerPortalUrl =
  'https://billing.stripe.com/p/login/eVa3eKaK94bC8Vy5kk';

const theme = createTheme({
  typography: {
    fontFamily: 'Lato, Arial, sans-serif',
    h4: {
      fontWeight: 700,
    },
    body1: {
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
  },
});

const Profile: React.FC = () => {
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState('');

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const userData = await Auth.currentAuthenticatedUser();
      setUserEmail(userData.attributes.email);
      setUserName(userData.attributes.name);
      setUserPhone(userData.attributes.phone_number);
      setIsEmailVerified(userData.attributes.email_verified);
      setSubscriptionStatus(
        userData.attributes['custom:subscriptionStatus'] || '',
      );
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleDeleteAccount = async () => {
    setOpenDialog(false); // Close the dialog regardless of the outcome
    try {
      await Auth.deleteUser();
      navigate('/');
    } catch (error) {
      console.error('Error deleting user account:', error);
    }
  };

  const handleVerifyEmail = () => {
    navigate('/verify-email');
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClickOpen = () => {
    if (subscriptionStatus === 'subscribed') {
      alert('Please cancel your subscription before deleting your account.');
      return;
    } else {
      handleDeleteAccount();
    }

    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          background: 'white',
          fontFamily: 'Lato, sans-serif',
        }}
      >
        {/* Content Box */}
        <Box
          sx={{
            flex: '1 0 auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontFamily: 'inherit',
          }}
        >
          <Container
            component="main"
            maxWidth="md"
            sx={{ fontFamily: 'inherit' }}
          >
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                fontFamily: 'inherit',
              }}
            >
              <Paper
                elevation={3}
                sx={{
                  padding: 4,
                  width: '100%',
                  maxWidth: 600,
                  marginTop: 3,
                  fontFamily: 'inherit',
                }}
              >
                <Grid
                  container
                  spacing={3}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item xs={12}>
                    <Avatar
                      sx={{
                        bgcolor: '#0C9FD4',
                        width: 56,
                        height: 56,
                        margin: 'auto',
                      }}
                    >
                      <AccountCircleIcon
                        sx={{ color: 'white' }}
                        fontSize="large"
                      />
                    </Avatar>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      component="h1"
                      variant="h4"
                      align="center"
                      gutterBottom
                      sx={{ fontFamily: 'inherit' }}
                    >
                      Profile
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" sx={{ fontFamily: 'inherit' }}>
                      <strong>Name:</strong> {userName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" sx={{ fontFamily: 'inherit' }}>
                      <strong>Email:</strong> {userEmail}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" sx={{ fontFamily: 'inherit' }}>
                      <strong>Phone Number:</strong> {userPhone}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" sx={{ fontFamily: 'inherit' }}>
                      <strong>Email Verified:</strong>{' '}
                      {isEmailVerified ? 'Yes' : 'No'}
                    </Typography>
                  </Grid>
                  {!isEmailVerified && (
                    <Grid item xs={12}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleVerifyEmail}
                        sx={{
                          mt: 2,
                          fontFamily: 'inherit',
                          background: '#0C9FD4',
                        }}
                      >
                        Verify Email
                      </Button>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => navigate('/edit-profile')}
                      sx={{
                        mt: 2,
                        mb: 2,
                        fontFamily: 'inherit',
                        background: '#0C9FD4',
                      }}
                    >
                      Edit Profile
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleLogout}
                      sx={{
                        mt: 2,
                        mb: 2,
                        fontFamily: 'inherit',
                        background: '#0C9FD4',
                      }}
                    >
                      Log Out
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Tooltip title="Permanently delete your account">
                      <Button
                        variant="contained"
                        style={{ backgroundColor: '#d32f2f' }}
                        onClick={handleClickOpen}
                        sx={{ mt: 2, fontFamily: 'inherit' }}
                      >
                        Delete Account
                      </Button>
                    </Tooltip>
                  </Grid>
                  {/* Conditionally render the Manage Subscription or Upgrade button */}
                  {subscriptionStatus === 'subscribed' ? (
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() =>
                          window.open(stripeCustomerPortalUrl, '_blank')
                        }
                        sx={{ mt: 2, fontFamily: 'inherit' }}
                      >
                        Manage Subscription
                      </Button>
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() =>
                          window.open(
                            stripeCheckoutUrl,
                            '_blank',
                            'noopener,noreferrer',
                          )
                        }
                        sx={{ mt: 2, fontFamily: 'inherit' }}
                      >
                        Upgrade
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </Box>
          </Container>
        </Box>
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default Profile;
