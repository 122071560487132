/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from '../API';
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getSneaker = /* GraphQL */ `query GetSneaker($id: ID!) {
  getSneaker(id: $id) {
    id
    shoe_name
    one_week_price
    three_months_price
    six_months_price
    image_url
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSneakerQueryVariables,
  APITypes.GetSneakerQuery
>;
export const listSneakers = /* GraphQL */ `query ListSneakers(
  $filter: ModelSneakerFilterInput
  $limit: Int
  $nextToken: String
) {
  listSneakers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      shoe_name
      one_week_price
      three_months_price
      six_months_price
      image_url
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSneakersQueryVariables,
  APITypes.ListSneakersQuery
>;
export const getArticle = /* GraphQL */ `query GetArticle($id: ID!) {
  getArticle(id: $id) {
    id
    slug
    author
    body
    createdAt
    date
    image_url
    title
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetArticleQueryVariables,
  APITypes.GetArticleQuery
>;
export const listArticles = /* GraphQL */ `query ListArticles(
  $filter: ModelArticleFilterInput
  $limit: Int
  $nextToken: String
) {
  listArticles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      slug
      author
      body
      createdAt
      date
      image_url
      title
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListArticlesQueryVariables,
  APITypes.ListArticlesQuery
>;
